import { gql } from '../../__generated__/gql'

export const TOGGLE_COMMUNITY_NOTIFICATION_READ_MUTATION = gql(`
  mutation toggleCommunityNotificationRead($notificationId: Int!) {
    toggleCommunityNotificationRead(input: {notificationId: $notificationId}) {
      notifications {
        id
        read
        actionPerformer {
          id
        }
        resource {
          user {
            id
          }
          post {
            id
          }
          comment {
            id
          }
        }
      }
    }
  }
`)

import { gql } from '../../__generated__/gql'

export const COMMUNITY_NOTIFICATION_MARK_ALL_READ_MUTATION = gql(`
  mutation communityNotificationsMarkAllRead {
    communityNotificationsMarkAllRead(input: {}) {
      notifications {
        id
      }
    }
  }
`)

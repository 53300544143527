import { gql } from '../../__generated__/gql'

export const COMMUNITY_PROFILE_NOTIFICATIONS_QUERY = gql(`
  query communityProfileNotifications {
    communityProfileNotifications {
      id
      actionPerformer {
        id
        firstName
        lastName
        picture
      }
      read
      actionType
      resource {
        user {
          id
        }
        post {
          id
          category {
            id
            title
          }
        }
        comment {
          id
        }
      }
    }
  }
`)

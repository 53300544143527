import { useState, useRef } from 'react'
import styles from './_avatar.module.scss'
import { useOutsideClick } from '../utils/hooks/useOutsideClick'
import { useAuth } from '../utils/auth'
import Image from 'next/legacy/image'
import AvatarDropdown from './AvatarDropdown'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

const Avatar: React.FC = () => {
  const { currentUser } = useAuth()
  const initials = `${currentUser.firstName.charAt(
    0
  )} ${currentUser.lastName.charAt(0)}`
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const ref = useRef()

  useOutsideClick(ref, isDropdownOpen, () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false)
    }
  })

  return (
    <div ref={ref} className={styles.avatarContainer}>
      <div
        className={classNames(styles.avatar, {
          [styles.isOpen]: isDropdownOpen
        })}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <Image
          alt={`${currentUser.firstName} ${currentUser.lastName}`}
          src={currentUser.picture}
          width={39}
          height={39}
        />

        <div className={styles.initials}>{initials}</div>
        {isDropdownOpen ? (
          <FontAwesomeIcon color="white" icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon color="white" icon={faChevronDown} />
        )}
      </div>

      {isDropdownOpen && (
        <AvatarDropdown setIsDropdownOpen={setIsDropdownOpen} />
      )}
    </div>
  )
}
export default Avatar

import { t } from '../utils/i18n'
import styles from './_announcement.module.scss'
import { useMutation } from '@apollo/client'
import Link from 'next/link'
import Image from 'next/legacy/image'
import {
  CommunityNotification,
  CommunityNotificationSettingEnum,
  CommunityNotificationTypeEnum
} from '../__generated__/graphql'
import {
  labCommunityCommentPath,
  labCommunityMemberPath,
  labCommunityPostPath
} from '../utils/routeHelpers'
import classNames from 'classnames'
import { TOGGLE_COMMUNITY_NOTIFICATION_READ_MUTATION } from '../gql'

interface DropdownProps {
  announcements: CommunityNotification[]
  isRemoveReadFilter: boolean
}

export interface NotificationOptionType {
  notification: CommunityNotificationTypeEnum
  notificationSettingType: CommunityNotificationSettingEnum
  title: string
}

const getAnnouncementLink = (announcement: CommunityNotification) => {
  switch (announcement.actionType) {
    case CommunityNotificationTypeEnum.PostLiked:
    case CommunityNotificationTypeEnum.NewPostFollowedCategory:
    case CommunityNotificationTypeEnum.MentionInPost:
      return labCommunityPostPath(announcement.resource.post)
    case CommunityNotificationTypeEnum.NewFollower:
      return labCommunityMemberPath(announcement.resource.user)
    case CommunityNotificationTypeEnum.CommentReply:
    case CommunityNotificationTypeEnum.CommentLiked:
    case CommunityNotificationTypeEnum.Mention:
      return labCommunityCommentPath(
        announcement.resource.post,
        announcement.resource.comment
      )
  }
}

export const notificationOptions: NotificationOptionType[] = [
  {
    notification: CommunityNotificationTypeEnum.PostLiked,
    notificationSettingType: CommunityNotificationSettingEnum.NotifyPostLiked,
    title: t('Someone liked your post')
  },
  {
    notification: CommunityNotificationTypeEnum.NewFollower,
    notificationSettingType: CommunityNotificationSettingEnum.NotifyNewFollower,
    title: t('You have a new Follower')
  },
  {
    notification: CommunityNotificationTypeEnum.CommentReply,
    notificationSettingType: CommunityNotificationSettingEnum.NotifyReply,
    title: t('Someone posted a reply')
  },
  {
    notification: CommunityNotificationTypeEnum.Mention,
    notificationSettingType: CommunityNotificationSettingEnum.NotifyMentioned,
    title: t('Someone mentioned you')
  },
  {
    notification: CommunityNotificationTypeEnum.NewPostFollowedCategory,
    notificationSettingType: CommunityNotificationSettingEnum.NotifyNewTopic,
    title: t('Someone posted in a topic you follow')
  },
  {
    notification: CommunityNotificationTypeEnum.CommentLiked,
    notificationSettingType:
      CommunityNotificationSettingEnum.NotifyCommentLiked,
    title: t('Someone liked your comment')
  },
  {
    notification: CommunityNotificationTypeEnum.MentionInPost,
    notificationSettingType:
      CommunityNotificationSettingEnum.NotifyMentionedInPost,
    title: t('Someone mentioned you in post')
  }
]

const LabNotice: React.FC<{ announcement: CommunityNotification }> = ({
  announcement
}) => {
  const member = announcement.actionPerformer
  const notification = notificationOptions.find(
    (notificationOption) =>
      notificationOption.notification === announcement.actionType
  )

  const [toggleRead] = useMutation(
    TOGGLE_COMMUNITY_NOTIFICATION_READ_MUTATION,
    {
      variables: { notificationId: announcement.id }
    }
  )

  const url = getAnnouncementLink(announcement)

  return (
    <div className={styles.notice}>
      <div
        className={classNames(styles.noticeIndicator, {
          [styles.noticeIndicatorInactive]: announcement.read
        })}
        onClick={() => toggleRead()}
      />
      <Link href={url}>
        <div className={classNames(styles.noticeText, styles.noticeTextNotify)}>
          <Image
            src={member.picture}
            width={36}
            height={36}
            alt={`${member.firstName} ${member.lastName}`}
          />
          <p className={styles.noticeTitle}>
            <span>{notification.title}</span>
          </p>
        </div>
      </Link>
    </div>
  )
}

const LabAnnouncementsDropdown: React.FC<DropdownProps> = ({
  announcements,
  isRemoveReadFilter
}) => {
  const notRead = announcements.filter((notice) => !notice.read)
  const announcementList = isRemoveReadFilter ? notRead : announcements

  return (
    <div className={styles.notificationItems}>
      {announcementList.map((announcement) => (
        <LabNotice key={announcement.id} announcement={announcement} />
      ))}
    </div>
  )
}

export default LabAnnouncementsDropdown

import { useState, useRef, useEffect, SetStateAction, Dispatch } from 'react'
import { t } from '../utils/i18n'
import styles from './_announcement.module.scss'
import classnames from 'classnames'
import { useMutation, useQuery } from '@apollo/client'
import { useOutsideClick } from '../utils/hooks/useOutsideClick'
import useErrorHandling from '../utils/hooks/useErrorHandling'
import { useAuth } from '../utils/auth'
import { ReactSVG } from 'react-svg'
import { MARK_ANNOUNCEMENTS_AS_READ_MUTATION } from '../gql/mutations/mark_announcements_as_read_mutation'
import {
  Announcement,
  CommunityNotification,
  User
} from '../__generated__/graphql'
import { routeType } from './AvatarDropdown'
import { useRouter } from 'next/router'
import { labPath } from '../utils/routeHelpers'
import isDevUser from '../utils/isDevUser'
import {
  COMMUNITY_NOTIFICATION_MARK_ALL_READ_MUTATION,
  COMMUNITY_PROFILE_NOTIFICATIONS_QUERY
} from '../gql'
import MainAnnouncementsDropdown from './MainAnnouncementsDropdown'
import LabAnnouncementsDropdown from './LabAnnouncementsDropdown'
import { getOperationName } from 'apollo-boost'

interface DropdownProps {
  announcements: Announcement[] | CommunityNotification[]
  isRemoveReadFilter: boolean
  setIsRemoveReadFilter: Dispatch<SetStateAction<boolean>>
  activeMenu: routeType
  setActiveMenu: Dispatch<SetStateAction<routeType>>
}

const Dropdown: React.FC<DropdownProps> = ({
  announcements,
  isRemoveReadFilter,
  setIsRemoveReadFilter,
  activeMenu,
  setActiveMenu
}) => {
  const notRead = announcements.filter((notice: Announcement) => !notice.read)
  const isAnyAnnouncements = Boolean(announcements.length)

  const errorHandling = useErrorHandling()
  const { currentUser, setCurrentUser } = useAuth()

  const [markAllMainAsRead] = useMutation(MARK_ANNOUNCEMENTS_AS_READ_MUTATION, {
    onError: errorHandling,
    update: (_cache, result) => {
      const { announcements } = result.data.markAnnouncementsAsRead
      setCurrentUser({
        ...currentUser,
        announcements
      } as User)
    }
  })

  const [markAllLabAsRead] = useMutation(
    COMMUNITY_NOTIFICATION_MARK_ALL_READ_MUTATION,
    {
      refetchQueries: [getOperationName(COMMUNITY_PROFILE_NOTIFICATIONS_QUERY)]
    }
  )

  const markRead = () => {
    if (activeMenu === 'LAB') {
      markAllLabAsRead()
    } else {
      const notReadIds = notRead.map((notice) => notice.id)
      markAllMainAsRead({ variables: { announcementIds: notReadIds } })
    }
  }

  return (
    <section className={styles.notificationDropdown}>
      <div className={styles.notificationDropdownHeader}>
        <p>{t('Notifications')}</p>

        {isAnyAnnouncements && (
          <div className={styles.notificationDropdownFilters}>
            <span onClick={() => setIsRemoveReadFilter(!isRemoveReadFilter)}>
              {isRemoveReadFilter ? t('Show Read') : t('Hide Read')}
            </span>
            <span onClick={markRead}>{t('Mark All As Read')}</span>
          </div>
        )}
      </div>

      {isDevUser(currentUser) && currentUser.labSubscriptionActive && (
        <div className={styles.announcementButtons}>
          <button
            onClick={() => setActiveMenu('MAIN')}
            className={activeMenu === 'MAIN' ? styles.isActive : ''}
          >
            WORSHIP ONLINE
          </button>

          <button
            onClick={() => setActiveMenu('LAB')}
            className={activeMenu === 'LAB' ? styles.isActive : ''}
          >
            LAB +
          </button>
        </div>
      )}

      <div className={styles.notificationItems}>
        {isAnyAnnouncements ? (
          activeMenu === 'LAB' ? (
            <LabAnnouncementsDropdown
              announcements={announcements as CommunityNotification[]}
              isRemoveReadFilter={isRemoveReadFilter}
            />
          ) : (
            <MainAnnouncementsDropdown
              announcements={announcements as Announcement[]}
              isRemoveReadFilter={isRemoveReadFilter}
            />
          )
        ) : (
          <div className={classnames(styles.notice, styles.noticeNoNotices)}>
            <p>{t('No New Notifications')}</p>
          </div>
        )}
      </div>
    </section>
  )
}

const Announcements: React.FC = () => {
  const ref = useRef(null)
  const router = useRouter()
  const activeRoute: routeType = router.route.startsWith(labPath())
    ? 'LAB'
    : 'MAIN'

  const [activeMenu, setActiveMenu] = useState<routeType>(activeRoute)
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [isRemoveReadFilter, setIsRemoveReadFilter] = useState<boolean>(
    !!sessionStorage.getItem('isRemoveReadFilter') || false
  )

  useOutsideClick(ref, isDropdownOpen, () => {
    if (isDropdownOpen) setIsDropdownOpen(false)
  })

  useEffect(() => {
    if (isRemoveReadFilter) {
      sessionStorage.setItem('isRemoveReadFilter', 'isRemoveReadFilter')
    } else {
      sessionStorage.removeItem('isRemoveReadFilter')
    }
  }, [isRemoveReadFilter])

  const { currentUser } = useAuth()
  const mainAnnouncements = currentUser?.announcements

  const { data } = useQuery(COMMUNITY_PROFILE_NOTIFICATIONS_QUERY)

  const labAnnouncements =
    (data?.communityProfileNotifications as CommunityNotification[]) || []

  const isMain = activeMenu === 'MAIN'
  const isLab = activeMenu === 'LAB'

  if ((isMain && !mainAnnouncements) || (isLab && !labAnnouncements))
    return <></>

  const isAnyMainAnnouncements = Boolean(mainAnnouncements.length)
  const isAnyLabAnnouncements = Boolean(labAnnouncements.length)

  const notReadMainNumber = mainAnnouncements.filter(
    (notice: Announcement) => !notice.read
  ).length
  const notReadLabNumber = labAnnouncements.filter(
    (notice) => !notice.read
  ).length

  const isAnyMainNotRead = Boolean(notReadMainNumber)
  const isAnyLabNotRead = Boolean(notReadLabNumber)

  return (
    <div ref={ref}>
      <div
        className={classnames(styles.notificationBell, {
          [styles.notificationBellWhite]:
            (isMain && isAnyMainAnnouncements) ||
            (isLab && isAnyLabAnnouncements),
          [styles.notificationBellPurple]:
            (isMain && isAnyMainNotRead) || (isLab && isAnyLabNotRead)
        })}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div>
          <ReactSVG src="/images/bell.svg" />
        </div>
        <span
          className={classnames(styles.notificationCircle, {
            [styles.displayBlock]:
              (isMain && isAnyMainNotRead) || (isLab && isAnyLabNotRead)
          })}
        >
          {isMain ? notReadMainNumber : notReadLabNumber}
        </span>
      </div>
      {isDropdownOpen && (
        <Dropdown
          announcements={isMain ? mainAnnouncements : labAnnouncements}
          isRemoveReadFilter={isRemoveReadFilter}
          setIsRemoveReadFilter={setIsRemoveReadFilter}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
      )}
    </div>
  )
}

export default Announcements

import { Dispatch, SetStateAction, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import styles from './_avatar.module.scss'
import { t } from '../utils/i18n'
import SignOutLink from './SignOutLink'
import classnames from 'classnames'
import Link from 'next/link'
import { BlockScrollContext } from '../pages/_app'
import Image from 'next/legacy/image'
import { useTheme } from 'next-themes'
import lightThemeIcon from '@public/images/light_theme.svg'
import darkThemeIcon from '@public/images/dark_theme.svg'
import Switcher from '../components/ui/SwitcherComponent'
import { UPDATE_USER_SETTINGS_MUTATION } from '../gql'
import { useMutation } from '@apollo/client'
import useErrorHandling from '../utils/hooks/useErrorHandling'
import { AppearanceEnum } from '../__generated__/graphql'
import { useAuth } from '../utils/auth'
import { useChat } from 'react-live-chat-loader'
import { useRouter } from 'next/router'
import {
  labCommunityMemberPath,
  labEmailNotificationsPath,
  labPath,
  storeUrl
} from '../utils/routeHelpers'
import { ReactSVG } from 'react-svg'
import isDevUser from '../utils/isDevUser'

interface DropdownProps {
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>
}

export type routeType = 'MAIN' | 'LAB' | 'STORE'

const DropdownMenu: React.FC<{
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>
  activeMenu: routeType
}> = ({ activeMenu, setIsDropdownOpen }) => {
  const { currentUser, isMusician } = useAuth()
  const [, loadChat] = useChat()
  const { setBlockScroll } = useContext(BlockScrollContext)

  const clickHandler = () => {
    setIsDropdownOpen(false)
    setBlockScroll(false)
  }
  const AccountItem = () => (
    <li>
      <Link href="/account" onClick={clickHandler}>
        <ReactSVG src={'../../images/settings-icon.svg'} />
        <span>{t('Account')}</span>
      </Link>
    </li>
  )

  const TeamItem = () => (
    <li className={classnames({ [styles.displayNone]: isMusician })}>
      <Link href="/team" onClick={clickHandler}>
        <ReactSVG src={'../../images/flag-icon.svg'} />
        <span>{t('Team')}</span>
      </Link>
    </li>
  )

  const RequestItem = () => (
    <li>
      <Link href="/song_requests" onClick={() => setIsDropdownOpen(false)}>
        <FontAwesomeIcon icon={faPlus} />
        <span>{t('Request a Song')}</span>
      </Link>
    </li>
  )

  const ChatItem = () => (
    <li>
      <a
        onClick={() => {
          loadChat({ open: true })
          clickHandler()
        }}
      >
        <ReactSVG src={'../../images/comment-icon.svg'} />
        <span>{t('Chat With Us')}</span>
      </a>
    </li>
  )

  const SignOutItem = () => (
    <li>
      <SignOutLink onClick={clickHandler} />
    </li>
  )

  const DownloadsItem = () => (
    <li>
      <Link href="/store/downloads" onClick={clickHandler}>
        <FontAwesomeIcon icon={faCaretDown} size="lg" width={19} />
        <span>{t('Downloads')}</span>
      </Link>
    </li>
  )

  const ProfileItem = () => (
    <li>
      <Link href={labCommunityMemberPath(currentUser)} onClick={clickHandler}>
        <ReactSVG src={'../../images/profile-icon.svg'} />
        <span>{t('Profile')}</span>
      </Link>
    </li>
  )

  const EmailNotificationsItem = () => (
    <li>
      <Link href={labEmailNotificationsPath()} onClick={clickHandler}>
        <ReactSVG src={'../../images/email-notifications-icon.svg'} />
        <span>{t('Email Notifications')}</span>
      </Link>
    </li>
  )

  const mainItems = [AccountItem, TeamItem, RequestItem, ChatItem, SignOutItem]
  const storeItems = [
    AccountItem,
    DownloadsItem,
    RequestItem,
    ChatItem,
    SignOutItem
  ]
  const labItems = [
    AccountItem,
    ProfileItem,
    EmailNotificationsItem,
    ChatItem,
    SignOutItem
  ]

  const menuItems =
    activeMenu === 'LAB'
      ? labItems
      : activeMenu === 'STORE'
        ? storeItems
        : mainItems

  return (
    <>
      {menuItems.map((Component, key) => (
        <Component key={key} />
      ))}
    </>
  )
}

const AvatarDropdown: React.FC<DropdownProps> = ({ setIsDropdownOpen }) => {
  const router = useRouter()
  const { theme, setTheme } = useTheme()
  const errorHandling = useErrorHandling()
  const { currentUser } = useAuth()

  const activeRoute: routeType = router.route.startsWith(storeUrl())
    ? 'STORE'
    : router.route.startsWith(labPath())
      ? 'LAB'
      : 'MAIN'

  const [updateUser] = useMutation(UPDATE_USER_SETTINGS_MUTATION, {
    onError: errorHandling,
    onCompleted: (data) => {
      setTheme(data.updateUserSettings.user.appearance || 'dark')
    }
  })

  const updateTheme = (theme: AppearanceEnum) => {
    updateUser({ variables: { input: { appearance: theme } } })
  }

  return (
    <div className={styles.dropdown}>
      <button
        type="button"
        className={styles.dropdownCloseButton}
        onClick={() => setIsDropdownOpen((open) => !open)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className={styles.avatarButtons}>
        <Link
          href="/"
          className={activeRoute === 'MAIN' ? styles.isActive : ''}
        >
          WORSHIP ONLINE
        </Link>
        {isDevUser(currentUser) && currentUser.labSubscriptionActive && (
          <Link
            href={labPath()}
            className={activeRoute === 'LAB' ? styles.isActive : ''}
          >
            LAB +
          </Link>
        )}
        <Link
          href={storeUrl()}
          className={activeRoute === 'STORE' ? styles.isActive : ''}
        >
          {t('STORE')}
        </Link>
      </div>
      <div className={styles.dropdownContainer}>
        <ul>
          <DropdownMenu
            setIsDropdownOpen={setIsDropdownOpen}
            activeMenu={activeRoute}
          />
        </ul>
      </div>
      <div
        className={classnames({
          [styles.displayNone]: activeRoute === 'LAB' || activeRoute === 'STORE'
        })}
      >
        {theme == 'light' ? (
          <div className={styles.darkTheme}>
            <Image alt="dark mode" src={darkThemeIcon} width={19} />
            <span className={styles.switcherLabel}>{t('Dark Mode')}</span>
            <Switcher
              onClick={() => {
                updateTheme(AppearanceEnum.Dark)
              }}
              className={styles.switcherDark}
              isChecked={false}
            />
          </div>
        ) : (
          <div className={styles.lightTheme}>
            <Image alt="light mode" src={lightThemeIcon} />
            <span className={styles.switcherLabel}>{t('Light Mode')}</span>
            <Switcher
              onClick={() => {
                updateTheme(AppearanceEnum.Light)
              }}
              isChecked={false}
              className={styles.switcherLight}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default AvatarDropdown
